import React from 'react';
import styled, { css } from 'styled-components';

import { AnimationWrap } from 'libs/animations';
import { getBadgeVariant } from 'components/products/Badge';
import Badge from 'components/products/Badge';

//#region Styling
const Header = styled.div`
	text-align: center;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	h3 {
		margin-bottom: ${p => p.theme.spacing.desktop.xxsmall};
	}
	p {
		margin-bottom: ${p => p.theme.spacing.desktop.xsmall};
		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	.width-limit {
		padding: 0;
	}
`;

const OuterWrapStyle = () => css`
	position: relative;
	background: white;
	box-shadow: ${p => p.theme.utils.boxShadow};
	transition: all 0.2s ease 0s;
	border-radius: ${p => p.theme.utils.borderRadius};
	outline: ${p =>
		p.active
			? `3px solid ${p.theme.colors.blue600}`
			: `3px solid transparent`};
	padding: ${p =>
		`${p.theme.spacing.desktop.small} ${p.theme.spacing.desktop.xsmall}`};

	${p =>
		p.theme.media.medium(css`
			padding: ${p =>
				`${p.theme.spacing.tablet.medium} ${p.theme.spacing.tablet.small}`};
		`)}

	&.has-badge__default {
		overflow: hidden;
	}

	${p =>
		(p.perrow === 1 &&
			css`
				${Header} {
					text-align: left !important;
					${p =>
						p.theme.media.mediumDown(css`
							h3 {
								margin-bottom: 10px;
							}
						`)}
				}
			`) ||
		(p.perrow === 3 &&
			p.grid === 'true' &&
			css`
				${p =>
					p.theme.media.large(css`
						display: grid;
						.product-wrap__inner {
							grid-row: 1 / 3;
							display: grid;
							grid-template-rows: subgrid;
						}
					`)}
			`)}

	${p =>
		p.grid === 'false' &&
		css`
			.product-wrap__inner {
				display: flex;
				flex-direction: column;
				height: 100%;
				justify-content: center;
				align-items: center;
				width: 100%;
				.product-wrap__header {
					${p =>
						p.perrow !== 1 &&
						css`
							align-items: center;
						`}
				}
				.product-wrap__content {
					flex-grow: 1;
					width: 100%;
					justify-content: flex-end;
				}
			}
		`}

	&.product-wrap {
		margin: ${p => `0 0 ${p.theme.spacing.desktop.small}`};
		${p =>
			p.theme.media.mediumDown(css`
				margin: ${p => `0 0 ${p.theme.spacing.desktop.small}`};
				display: flex;
				flex-direction: column;
			`)}

		.product-wrap__content {
			padding-top: 20px;
			${p =>
				p.theme.media.mediumDown(css`
					flex-grow: 1;
					padding-top: 0;
				`)}
			.product__price {
				${p =>
					p.perrow !== 1 &&
					css`
						text-align: center;
					`}
			}
			.product__features {
				padding-top: 20px;
			}
		}

		// Remove margins for siblings if any siblings have highlighted style
		${p =>
			p.anyhighlighted === 'true' &&
			css`
				&:not(.highlighted):not(.horizontal) {
					${p =>
						p.theme.media.large(css`
							margin-top: ${p =>
								p.theme.spacing.desktop.small} !important;
							margin-bottom: ${p =>
								p.theme.spacing.desktop.small} !important;
						`)}
				}
			`}

		// Highlighted style
		&.highlighted {
			background: ${p => p.theme.colors.green200};
			box-shadow: ${p => p.theme.utils.boxShadowHard};
			${p =>
				p.theme.media.large(css`
					&:not(.horizontal) {
						padding: ${p =>
							`70px ${p.theme.spacing.desktop.small}`};
						margin-bottom: 0 !important;
					}
				`)}
		}

		// Horizontal style when is not power-product (Strømprodukt)
		&.horizontal:not(.contentfulinnholdstromprodukt) {
			${p =>
				p.theme.media.medium(css`
					margin: ${p => `${p.theme.spacing.desktop.small} 0`};
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					&:first-of-type {
						margin-top: 0;
					}
					&.highlighted {
						padding: ${p =>
							`${p.theme.spacing.tablet.medium} ${p.theme.spacing.tablet.small}`};
					}
					.product-wrap__header {
						margin-bottom: 0;
						text-align: left;
						> div {
							text-align: left;
						}
						.product__price {
							text-align: left;
							margin: ${p =>
								`${p.theme.spacing.desktop.xxsmall} 0 0`};
							span,
							em {
								font-size: 17px;
								line-height: 29px;
							}
						}
					}
					.product-wrap__content {
						ul {
							${p =>
								p.perrow === 1 &&
								css`
									${p =>
										p.theme.media.large(css`
											column-count: 2;
											column-gap: 120px;
										`)}
								`}
						}
					}
					${p =>
						p.perrow !== 1 &&
						css`
							.product-wrap__content {
								max-width: 400px;
								justify-content: flex-end;
							}
						`}
				`)}
		}

		// Product
		&.contentfulinnholdprodukt {
			// Smaller padding for 3 products per row
			${p =>
				p.perrow === 3 &&
				css`
					padding: ${p =>
						`${p.theme.spacing.desktop.xsmall} ${p.theme.spacing.desktop.xsmall} ${p.theme.spacing.desktop.small}`};
				`}

			.product-wrap__content {
				padding-top: 0;
			}
		}

		// Strømprodukt
		&.contentfulinnholdstromprodukt {
			.product-wrap__content {
				padding-top: 10px;
			}
			.box .intro-text {
				max-width: 100% !important;
			}
		}
	}
`;

const OuterWrap = styled.div`
	${OuterWrapStyle}
`;

const AnimationWrapper = styled(AnimationWrap)`
	${OuterWrapStyle}
`;

//#endregion

/**
 * Represents a wrapper component for displaying a product, including optional badges and customizable content.
 *
 * @param {Object} props - The properties for the ProductWrapper component.
 * @param {ReactNode} header - The header content for the product.
 * @param {ReactNode} children - The main content for the product.
 * @param {boolean} [props.active] - Flag to indicate if the product is active.
 * @param {string} [props.highlighted] - Flag to indicate if the product is highlighted.
 * @param {string} [props.clientfavorite] - Flag to indicate if the product is a client favorite.
 * @param {string} [props.newproduct] - Flag to indicate if the product is a new product.
 * @param {string} [props.customBadge] - Custom text for a badge displayed on the product.
 * @param {boolean} [props.campaignPrice] - Flag to indicate if the product has a campaign price.
 * @param {number} [props.perrow] - Number of products to display per row in the grid.
 * @returns {ReactNode} - A React element representing the ProductWrapper component.
 */
export default function ProductWrapper({ header, children, ...props }) {
	// Determine class names;
	let className = `${props?.className || ''} product-wrap`;
	if (props?.recommended === 'true' || props?.highlighted === 'true') {
		className += ' highlighted';
	}
	if (props?.clientfavorite === 'true') className += ' client-favorite';
	if (props?.perrow === 1) className += ' horizontal';
	const type = props?.__typename || props?.internal?.type;
	if (type) className += ` ${type?.toLowerCase()}`;

	// Determine badge variant (if any)

	const badgeVariant = getBadgeVariant(props);
	if (badgeVariant) className += ` has-badge__${badgeVariant?.variant}`;

	return (
		<ConditionalWrapper {...props} className={className}>
			<div className="product-wrap__inner">
				{!!badgeVariant && <Badge {...badgeVariant} />}

				{header && (
					<Header className="product-wrap__header">{header}</Header>
				)}

				{children && (
					<Content className="product-wrap__content">
						{children}
					</Content>
				)}
			</div>
		</ConditionalWrapper>
	);
}

/**
 * A component that conditionally wraps its children based on the presence of certain props.
 *
 * @param {Object} props - The properties for the ConditionalWrapper component.
 * @param {boolean} [props.doanimate] - Flag indicating whether animation should be applied.
 * @param {Object} props.transitions - Transition configuration for animation (if applied).
 * @param {ReactNode} children - The React elements to be conditionally wrapped.
 * @returns {ReactNode} - A React element representing the wrapped children based on conditions.
 */
function ConditionalWrapper({ transitions, children, ...props }) {
	const properties = {
		className: props?.className,
		active: props?.active,
		perrow: props?.perrow,
		grid: props?.grid,
		anyhighlighted: props?.anyhighlighted,
		children,
	};

	if (props?.doanimate === 'true') {
		return <AnimationWrapper {...properties}>{children}</AnimationWrapper>;
	}
	return <OuterWrap {...properties}>{children}</OuterWrap>;
}

const Grid = styled.div`
	// More than one product and more than one per row
	${p =>
		p.perrow !== 1 &&
		p.total > 1 &&
		css`
			${p =>
				p.theme.media.medium(css`
					display: grid;
					grid-column-gap: 20px;
					grid-template-columns: repeat(2, 1fr) !important;
				`)}
			${p =>
				p.theme.media.large(css`
					grid-template-columns: ${p =>
						(p.perrow && `repeat(${p.perrow}, 1fr) `) ||
						'repeat(3, 1fr)'} !important;
				`)}
		`}

	${p =>
		p.perrow !== 1 &&
		p.total === 1 &&
		css`
			${p =>
				p.theme.media.medium(css`
					display: grid;
					grid-column-gap: 20px;
					grid-template-columns: repeat(2, 1fr) !important;
				`)}
		`}

	// Zero margin-bottom for last row products
	> .product-wrap {
		${p =>
			p.theme.media.large(css`
				&:nth-child(${p => p.perrow}n+1):nth-last-child(
						-n + ${p => p.perrow}
					),
				&:nth-child(${p => p.perrow}n+1):nth-last-child(
						-n + ${p => p.perrow}
					)
					~ div {
					margin-bottom: 0;
				}
			`)}

		${p =>
			p.theme.media.mediumOnly(css`
				&:nth-child(2n + 1):nth-last-child(-n + 2),
				&:nth-child(2n + 1):nth-last-child(-n + 2) ~ div {
					margin-bottom: 0 !important;
				}
			`)}

		.text {
			margin: 0 0;
		}
	}

	.product-wrap {
		// Horizontal view
		&.horizontal:not(.contentfulinnholdstromprodukt) {
			.product-wrap__inner {
				width: 100%;
				${p =>
					p.theme.media.medium(css`
						flex-direction: ${p =>
							(p.perrow === 1 && 'column') || 'row'};
						justify-content: space-between;
					`)}
				.product-wrap__header {
					${p =>
						p.theme.media.medium(css`
							text-align: left;
						`)}
				}
				.button-wrap {
					${p =>
						p.theme.media.medium(css`
							display: flex;
							align-items: flex-end;
							flex-direction: column;
						`)}
					button {
						${p =>
							p.theme.media.medium(css`
								max-width: 245px;
								width: 100%;
								padding: 12px 12px;
							`)}
					}
				}
			}
		}

		&.contentfulinnholdtelekomprodukter {
			.product-wrap__inner {
				${p =>
					p.theme.media.medium(css`
						height: 100%;
						display: flex;
						width: 100%;
						flex-direction: column;
						.product-wrap__content {
							margin-top: auto;
						}
					`)}
			}
			&.horizontal {
				.product-wrap__inner {
					width: 100%;
					${p =>
						p.theme.media.medium(css`
							flex-direction: row !important;
							justify-content: space-between !important;
							align-items: flex-end;
							display: flex;
							.product-wrap__content {
								min-width: 245px;
							}
						`)}
				}
			}
		}
	}
`;

/**
 * A wrapper component for a grid layout.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {ReactNode} props.children - The child elements to be displayed in the grid.
 * @param {number} [props.perrow=3] - The number of items to display per row in the grid.
 * @returns {ReactElement|null} The Grid component with the passed properties and children, or null if no children are provided.
 */
export function GridWrapper({ children, perrow = 3, ...props }) {
	if (!children) return null;

	return (
		<Grid perrow={perrow} {...props}>
			{children}
		</Grid>
	);
}
