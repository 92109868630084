import React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import ProductWrapper from '../ProductWrapper';
import Button from 'components/forms/Button';
import Heading from 'libs/heading';
import LazyImage from 'components/LazyImage';
import Points from '../Points';

const ButtonWrap = styled.div`
	margin-top: auto !important;
	padding-top: ${p => p.theme.spacing.desktop.xsmall};
	${p =>
		p.perrow === 1 &&
		css`
			${p =>
				p.theme.media.medium(css`
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
				`)}
		`}
`;

/**
 * Represents a telekom product
 * @param {integer} perrow - How many products per row
 * @param {integer} total - Total number of products
 * @param {boolean} transitions - Whether the product should have transitions or not
 * @param {string} anyhighlighted - Whether any of the products are highlighted
 * @param {...object} product - Rest of the properties for the component
 */
export default function TelekomProduct({
	perrow = 1,
	total = 0,
	transitions = false,
	anyhighlighted = 'false',
	...product
}) {
	return (
		<ProductWrapper
			perrow={perrow}
			grid="false"
			className="contentfulinnholdtelekomprodukter"
			anyhighlighted={anyhighlighted}
			highlighted={product?.settings?.includes('Anbefalt')?.toString()}
			clientfavorite={product?.settings
				?.includes('Kundefavoritt')
				?.toString()}
			header={
				<>
					{product?.image?.file?.url && perrow > 1 && (
						<LazyImage
							{...product?.image}
							width={300}
							height={300}
							sizes={[
								{ w: 300, h: 300 },
								{ w: 120, h: 120 },
							]}
							fit="fill"
							style={{ marginBottom: '10px' }}
						/>
					)}
					{product?.title && (
						<Heading level={product?.headinglevel || 'h3'}>
							{product?.title}
						</Heading>
					)}
					{product?.shortDesc && (
						<p
							className="product__desc"
							style={{ marginTop: '10px' }}>
							{product?.shortDesc}
						</p>
					)}

					{(perrow > 1 || total === 1) && (
						<Points
							included={product?.included || []}
							notIncluded={product?.notIncluded}
							className="product__features"
							style={{ marginTop: '30px' }}
						/>
					)}
				</>
			}>
			<ButtonWrap perrow={perrow} className="button-wrap">
				<Button
					title={
						product?.formPage?.slug
							? 'Gå til bestillingsskjema'
							: 'Sjekk om du kan få fiber'
					}
					stretch={true}
					onClick={() =>
						navigate(product?.formPage?.slug || '/tv-og-internett')
					}>
					{product?.formPage?.slug
						? 'Gå til bestillingsskjema'
						: 'Sjekk om du kan få fiber'}
				</Button>
			</ButtonWrap>
		</ProductWrapper>
	);
}
