import React from 'react';
import styled, { css } from 'styled-components';

import { getComponentSettings, outputPlainText } from 'libs/content';
import { GridWrapper } from './ProductWrapper';
import MaxWidth from 'layouts/max-width';
import PowerProduct from './power/Product';
import SolarProduct from './solar/Product';
import CustomProducts from './custom/CustomProducts';
import TelekomProduct from './telekom/Product';
import Button from 'components/forms/Button';
import Spacing from 'layouts/Spacing';
import TitleAndText from 'parts/title-and-text/TitleAndText';

/**
 * Represents a grid of products.
 * @param {string} title - The title of the component.
 * @param {string} intro - The introduction text of the component.
 * @param {array} settings - The settings of the component.
 * @param {object} spacing - The spacing of the component.
 * @param {object} customButton - An object containing information about a custom button to display.
 * @param {boolean} showPrice - Whether to show the price of the products.
 * @param {number} perrow - The number of products to display per row.
 * @param {boolean} transitions - Whether to use transitions when displaying the products.
 * @param {number} index - The index of the component in the list of components.
 * @param {array} products - The list of products to display.
 * @param {string} headinglevel - The heading level of the title.
 * @param {...object} props - The rest of the parameters for the component.
 */
export default function ProductColumns({ ...props }) {
	const componentSettings =
		getComponentSettings({ settings: props?.settings }) || {};

	const productType =
		(props?.products?.length > 0 && props?.products[0]?.internal?.type) ||
		'Bedriftsportalen';

	return (
		<MaxWidth
			className="component__product-columns"
			data-cy="component__product-columns">
			<Spacing id="bestill" {...props}>
				{componentSettings?.hidetitle !== 'true' && (
					<TitleAndText
						{...props}
						text={props?.intro}
						nested={true}
						buttons={null} // Hide buttons, as they are displayed below the products
					/>
				)}

				<ProductGrid
					productType={productType}
					componentSettings={componentSettings}
					{...props}
				/>
			</Spacing>
		</MaxWidth>
	);
}

/**
 * Renders a grid of products based on the product type and settings.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.productType - The type of the products to display. Can be 'ContentfulInnholdTelekomProdukter', 'ContentfulInnholdStromprodukt', 'ContentfulInnholdSolceller', or 'Bedriftsportalen'.
 * @param {Object} props.componentSettings - Settings for the component, such as whether to highlight the default product.
 * @param {Object} props.customButton - An object containing information about a custom button to display.
 * @param {boolean} props.showPrice - Whether to show the price of the products.
 * @param {number} props.perrow - The number of products to display per row.
 * @param {boolean} props.transitions - Whether to use transitions when displaying the products.
 * @param {number} props.index - The index of the component in the list of components.
 * @param {Array} props.products - The list of products to display.
 *
 * @returns {JSX.Element} A grid of products.
 */
function ProductGrid({ productType, componentSettings, ...props }) {
	// Define settings for the products
	const settings = {
		id: `${productType?.toLowerCase()}-products-grid`,
		highlighted:
			componentSettings?.highlightdefaultproduct === 'true'
				? 'true'
				: 'false',
		customButton: props?.customButton,
		showPrice: props?.showPrice,
		perrow:
			(!!props?.perrow && props?.perrow) ||
			(props?.products?.length === 1 && 1) ||
			(productType === 'ContentfulInnholdStromprodukt' && 1) ||
			3,
		transitions: props?.transitions,
		componentindex: props?.index,
		total: props?.products?.length,
	};

	/** ---------- Power products and Solar products  ---------- */
	if (
		[
			'ContentfulInnholdStromprodukt',
			'ContentfulInnholdSolceller',
		].includes(productType)
	) {
		const ProductComponent =
			productType === 'ContentfulInnholdStromprodukt'
				? PowerProduct
				: SolarProduct;

		return (
			<>
				<GridWrapper data-cy={settings?.id} {...settings}>
					{props?.products?.map((product, i) => (
						<ProductComponent
							key={i}
							productindex={i}
							location={props?.pagesettings || props?.location}
							{...product}
							{...settings}
						/>
					))}
				</GridWrapper>
				<BottomContent {...props} {...componentSettings} />
			</>
		);
	}

	/** ---------- Bedriftsportalen-products ---------- */
	if (productType === 'Bedriftsportalen') {
		return (
			<>
				<CustomProducts {...props} perrow={settings?.perrow} />
				<BottomContent {...props} {...componentSettings} />
			</>
		);
	}

	/** ---------- Telekom-products ---------- */
	if (productType === 'ContentfulInnholdTelekomProdukter') {
		return (
			<>
				<GridWrapper data-cy={settings?.id} {...settings}>
					{props?.products?.map((product, i) => (
						<TelekomProduct
							key={i}
							total={props?.products?.length}
							anyhighlighted={
								props?.products?.some(p =>
									p?.settings?.includes('Anbefalt')
								)
									? 'true'
									: 'false'
							}
							{...product}
							{...settings}
						/>
					))}
				</GridWrapper>
				<BottomContent {...props} {...componentSettings} />
			</>
		);
	}
}

//#region Styling
const BottomText = styled.div`
	text-align: ${p => (p.$centered === 'true' && 'center') || 'left'};
	margin-top: ${p => p.theme.spacing.desktop.small};
`;
const ButtonsWrapper = styled.div`
	text-align: ${p => (p.$centered === 'true' && 'center') || 'left'};
	margin-top: ${p => p.theme.spacing.desktop.small};
	${p =>
		p.theme.media.mediumDown(css`
			margin-top: ${p => p.theme.spacing.tablet.small};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: ${p => p.theme.spacing.mobile.small};
		`)}
	button,
	a {
		margin: 0 20px 20px 0;
	}
`;
//#endregion

/**
 * Renders the bottom content of a product, including any text and buttons.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.textAboveButtons - The text to display above the buttons.
 * @param {Array} props.buttons - An array of button objects to display. Each object should have properties for the button, such as 'text'.
 *
 * @returns {JSX.Element} The bottom content of a product.
 */
function BottomContent({ ...props }) {
	return (
		<>
			{props?.textAboveButtons && (
				<BottomText {...props}>
					{outputPlainText(props?.textAboveButtons)}
				</BottomText>
			)}

			{props?.buttons?.length > 0 && (
				<ButtonsWrapper $centered="true">
					{props?.buttons?.map((btn, i) => (
						<Button key={i} {...btn}>
							{btn?.text}
						</Button>
					))}
				</ButtonsWrapper>
			)}
		</>
	);
}
